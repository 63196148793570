import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Field, Button, Autocomplete } from '@renolib/renolib-ui-kit';
import { Alert, Input, Space, InputNumber } from 'antd';

import uploadDocumentHelper from '../../../../utils/upload-document-helper';
import { uploadDocumentFileTypes, uploadDocumentTypes } from '../../../../utils/enums';
import { uploadDocumentMaxDates, uploadDocumentMinDates } from '../../../../utils/form_utils/date-picker-config';
import dossierCEEService from '../../../../store/services/dossier-cee.service';
import { isNonEmptyObject, isNonEmptyString, isValidValue } from '../../../../utils';
import { LABEL_AUTOCOMPLETE_DOSSIER, LABEL_INPUT_COMMENT_OPTIONAL, LABEL_SELECT_DOCUMENT_TYPE, LABEL_SELECT_OPERATION_RELATED_TO_ATTESTATION } from '../../../../utils/form_labels';
import { maPrimeRenovDocumentTypes } from '../helpers/maPrimeRenovDocumentTypes';
import uploadDocumentService from '../../../../store/services/upload-document.service';

import Form from '../../../helpers/Form';
import DatePicker from '../../../helpers/DatePicker';
import ViewerFormContainer from '../../../helpers/ViewerFormContainer';
import PDFViewer from '../../../helpers/PDFViewer';
import ImageViewer from '../../../helpers/ImageViewer';
import { SelectOperations } from './SelectOperations';
import UploadMultipleFiles from '../../../helpers/UploadMultipleFiles';
import DropZone from '../../../helpers/DropZone';
import Loading from '../../../helpers/Loading';
import { UploadDocumentsDropZone } from '../../../dropzone/UploadDocumentsDropZone';
import { useFinder } from '../../quotation/edition/main/wasteManagement/hooks/useFinder';
import { TaxNoticeForm } from './TaxNoticeForm';
import operationSheetNames from '../../../../utils/operation-sheet-names';
import { displayDpeOrAuditEnergeticForm } from './displayDpeOrAuditEnergeticForm';

function CreateUploadDocumentForm({
    defaultFormState = undefined,
    formState,
    formErrors,
    onChange,
    onSave,
    updateFormState,
    disabledFieldsSelection = false,
    operationSheets,
    checkIfDocumentExist,
    message,
    dossier,
    alerts = [],
    onResetDocumentCheckMessage,
}) {
    const [loading, setLoading] = useState(false);
    const [dossierCEELabel, setDossierCEELabel] = useState('');
    const [grantLetterAmount, setGrantLetterAmount] = useState(0);
    const [grantLetterDate, setGrantLetterDate] = useState(undefined);
    const [grantLetterDossierReference, setGrantLetterDossierReference] = useState('');

    const { documentType, fileName, fileType, file } = formState;
    const fileNameRef = useRef(null);

    const allSitePhotos = [uploadDocumentTypes.SITE_PHOTO_BEFORE_RENOVATION, uploadDocumentTypes.SITE_PHOTO_AFTER_RENOVATION];

    const formatSearchResult = (results) =>
        results
            .filter((dossierCEE) => (![uploadDocumentTypes.QUOTATION, uploadDocumentTypes.AUDIT_BEFORE_RENOVATION].includes(documentType) ? isValidValue(dossierCEE.number) : true))
            .map((dossierCEE) => ({ label: dossierCEE.filterableQuotationProperties.label, all: dossierCEE, value: dossierCEE._id }));

    const search = useCallback(
        (searchString) => {
            let query = { searchString, filterDossiersCEEWithoutPremium: false, filterDossiersCEEWithCompletedQuotation: true, withDossiersMaPrimeRenov: false };
            if (Object.keys(maPrimeRenovDocumentTypes).includes(documentType)) {
                query = { ...query, withDossiersMaPrimeRenov: true };
            }
            if (documentType === uploadDocumentTypes.CLAIM_ASSIGNMENT) query = { ...query, filterDossiersWithVitogazNegociatedByRenolib: true };
            if (documentType === uploadDocumentTypes.CADRE_CONTRIBUTION) query = { ...query, filterDossiersWithContractNegotiatedByPro: true };
            return dossierCEEService.searchDossiersCEE(query);
        },
        [documentType]
    );

    const {
        searchedEntities: dossiersCEE,
        isLoading,
        onSearch,
    } = useFinder({
        finderService: {
            search,
        },
        formatSearchResult,
    });

    const [informationOperationSheetValidity, setInformationOperationSheetValidity] = useState('');
    const [selectedDossierCee, setSelectedDossierCee] = useState(defaultFormState?.dossierCEE);

    function handleSelectDossierCEE({ option }) {
        const stateUpdates = {};
        if (isNonEmptyObject(option.all.quotation)) {
            stateUpdates.quotationId = option.all.quotation.id;
            stateUpdates.quotation = option.all.quotation;
        }
        setSelectedDossierCee(option.all);
        onChange({ name: 'dossierCEE', value: option.all, stateUpdates });
        onChange({ name: 'dossierCEEId', value: option.value, propertyToResets: ['signatureDate', 'accreditationExpirationDate', 'operations'], stateUpdates });

        checkIfDocumentExist({ value: option.value });
    }

    useEffect(() => {
        const { signatureDate } = formState;
        if (documentType === uploadDocumentTypes.QUOTATION && signatureDate && selectedDossierCee) {
            setInformationOperationSheetValidity(uploadDocumentHelper.getQuotationCompliancyInformationMessage(selectedDossierCee, operationSheets, signatureDate));
        }
        if (formState.fileName && !fileNameRef.current) fileNameRef.current = formState.fileName;
    }, [selectedDossierCee, documentType, formState, operationSheets]);

    const canShowObsoleteOperationWarning = useCallback(() => {
        const { quotation = {} } = formState;
        const { operations = [] } = quotation;
        return (
            documentType === uploadDocumentTypes.QUOTATION &&
            operations.find((operation) =>
                [
                    operationSheetNames.BAR_TH_104,
                    operationSheetNames.BAR_TH_104_CDP,
                    operationSheetNames.BAR_TH_164V_A36_2_CDP,
                    operationSheetNames.BAR_TH_164V_A36_2,
                    operationSheetNames.BAR_TH_106,
                    operationSheetNames.BAR_TH_106_CDP,
                ].includes(operation.operationSheetName)
            )
        );
    }, [documentType, formState]);

    const getMinSignatureDate = useCallback(() => {
        return {
            [uploadDocumentTypes.QUOTATION]: uploadDocumentMinDates.quotationSignatureDate(selectedDossierCee?.quotation?.quotationIssueDate),
            [uploadDocumentTypes.SWORN_STATEMENT]: uploadDocumentMinDates.swornStatementSignatureDate(selectedDossierCee?.invoice?.invoiceIssueDate),
            [uploadDocumentTypes.POST_RENOVATION_REPORT]: uploadDocumentMinDates.postRenovationSignatureDate(selectedDossierCee?.invoice?.operations),
            [uploadDocumentTypes.SUMMARY_STATEMENT]: uploadDocumentMinDates.summaryStatementSignatureDate(selectedDossierCee?.quotation?.signatureDate),
        }[documentType];
    }, [documentType, selectedDossierCee]);

    const getMaxSignatureDate = useCallback(() => {
        return {
            [uploadDocumentTypes.QUOTATION]: uploadDocumentMaxDates.quotationSignatureDate({ selectedDossierCee, isAnOperationObsoleteIn2024: canShowObsoleteOperationWarning() }),
            [uploadDocumentTypes.SWORN_STATEMENT]: uploadDocumentMaxDates.swornStatementMaxSignatureDate(),
            [uploadDocumentTypes.POST_RENOVATION_REPORT]: uploadDocumentMaxDates.postRenovationSignatureDate(selectedDossierCee?.invoice?.operations),
        }[documentType];
    }, [documentType, selectedDossierCee, canShowObsoleteOperationWarning]);

    const getExtraJSXByDocumentType = useCallback(() => {
        if (!selectedDossierCee) return <div />;
        const { quotation } = selectedDossierCee;
        if ([uploadDocumentTypes.SITE_PHOTO_AFTER_RENOVATION, uploadDocumentTypes.SITE_PHOTO_BEFORE_RENOVATION].includes(documentType)) {
            return <SelectOperations quotation={quotation} onChange={onChange} error={formErrors?.operations} />;
        }
        if ([uploadDocumentTypes.AUDIT_AFTER_RENOVATION, uploadDocumentTypes.AUDIT_BEFORE_RENOVATION].includes(documentType)) return <>{displayAuditForm(formErrors, onChange)}</>;
        return <div />;
    }, [documentType, selectedDossierCee, formErrors, onChange]);

    function handleDocumentTypeChange({ name, value }) {
        if (value === uploadDocumentTypes.OTHER) onChange({ name: 'fileName', value: '' });
        else if (!fileName) onChange({ name: 'fileName', value: fileNameRef.current });
        onChange({ name, value });
        if (isNonEmptyString(message?.select_dossierCEE) && onResetDocumentCheckMessage) onResetDocumentCheckMessage();
    }

    async function handleFileChange({ file }) {
        const { fileName, fileType } = uploadDocumentHelper.extractFileNameAndFileType(file);
        let additionnalStateUpdates = {};

        if (formState?.documentType === uploadDocumentTypes.QUOTATION) additionnalStateUpdates = await handleCheckDocumentIfItsAQuotation(file);
        if (formState?.documentType === uploadDocumentTypes.ANAH_GRANT_LETTER) additionnalStateUpdates = await handleCheckGrantLetter(file);

        updateFormState({ ...defaultFormState, fileName, fileType, file, ...additionnalStateUpdates });
    }

    async function handleCheckDocumentIfItsAQuotation(file) {
        const formData = new FormData();
        formData.append('file', file);

        setLoading(true);
        const res = await uploadDocumentService.getDossierByQuotationFile(formData, dossier?.organization);
        setLoading(false);
        const { quotationNumber, quotationPages, quotation, quotationId, dossierCEEId, label } = res.data;
        setDossierCEELabel(label);
        handleSelectDossierCEE({ option: { all: { quotation, quotationId }, value: dossierCEEId } });

        return { fileQuotationNumber: quotationNumber, fileQuotationPages: quotationPages };
    }

    async function handleCheckGrantLetter(file) {
        const formData = new FormData();
        formData.append('file', file);

        setLoading(true);
        const res = await uploadDocumentService.getAnahGrantLetterAmount(formData);

        setLoading(false);
        const { grantLetterAmount, grantLetterDate, grantLetterDossierReference, message } = res.data;
        setGrantLetterAmount(!message ? grantLetterAmount : 0);
        setGrantLetterDate(!grantLetterDate || grantLetterDate === 'server' ? undefined : grantLetterDate);
        setGrantLetterDossierReference(!grantLetterDossierReference ? '' : grantLetterDossierReference);

        return { grantLetterAmount, grantLetterDate, grantLetterDossierReference, grantLetterMessage: message };
    }

    function getDossierCEEDefaultValue() {
        if (defaultFormState?.dossierCEE?.filterableQuotationProperties?.label) return defaultFormState.dossierCEE.filterableQuotationProperties.label;
        if (dossierCEELabel) return dossierCEELabel;
        return '';
    }

    useEffect(() => {
        if (!defaultFormState || selectedDossierCee) return;
        setSelectedDossierCee(defaultFormState.dossierCEE);
    }, [defaultFormState, selectedDossierCee]);

    function getAuthorizedFileTypes() {
        if ([uploadDocumentTypes.QUOTATION, uploadDocumentTypes.TIME_STAMPED_PHOTO].includes(formState?.documentType)) return ['application/pdf'];
        return undefined;
    }

    function handleDossierReferenceChange(type, { value }) {
        // eslint-disable-next-line no-unused-vars
        const [prefix, referenceYear, referenceNumber] = grantLetterDossierReference.split('-');
        let newDossierReference;

        if (type === 'year') newDossierReference = `MPR-202${value}-${referenceNumber || ''}`;
        else if (type === 'reference') newDossierReference = `MPR-202${referenceYear.slice(-1) || ''}-${value}`;
        setGrantLetterDossierReference(newDossierReference);
        onChange({ name: 'grantLetterDossierReference', value: newDossierReference });
    }

    return (
        <div>
            <ViewerFormContainer>
                {displayFileViewer(fileType, file)}
                <Form>
                    <Form.Group>
                        <Field
                            type='select'
                            fluid
                            name='documentType'
                            disabled={disabledFieldsSelection}
                            defaultValue={uploadDocumentHelper.getDocumentTypeLabel(defaultFormState?.documentType)}
                            label={LABEL_SELECT_DOCUMENT_TYPE}
                            options={uploadDocumentHelper.getSelectDocumentTypeOptions(operationSheets)}
                            error={formErrors.documentType}
                            onChange={handleDocumentTypeChange}
                            lightBackground={true}
                        />
                        {message?.select_dossierCEE && <Alert message={message?.select_dossierCEE} type='warning' showIcon banner />}
                        {alerts.map(({ message, type }, index) => (
                            <Alert className='alert' key={index} message={message} type={type} showIcon banner />
                        ))}
                    </Form.Group>
                    {uploadDocumentHelper.canDisplayDropZone(formState) && (
                        <Form.Group>
                            <DropZone className='bg-white' light={false} onChange={handleFileChange} defaultAuthorizedFileTypes={getAuthorizedFileTypes()} maxFileSize={40} includeFileTypes>
                                <UploadDocumentsDropZone maxFileSize={40} />
                            </DropZone>
                            {formErrors?.file && <span style={{ color: '#B7090F', display: 'block' }}>{formErrors.file}</span>}
                        </Form.Group>
                    )}

                    {formState.file && (
                        <Form.Group>
                            <Field type='text' fluid name='fileName' label='Nom du document' defaultValue={fileName} onChange={onChange} error={formErrors.fileName} lightBackground={true} />
                            {formState?.documentType === uploadDocumentTypes.QUOTATION && !formState?.fileQuotationNumber && (
                                <Alert message="Attention, ce fichier n'est pas un devis, ou c'est un devis rattaché à aucun compte." type='warning' showIcon />
                            )}
                        </Form.Group>
                    )}

                    {formState.file && formState.documentType === uploadDocumentTypes.ANAH_GRANT_LETTER && (
                        <>
                            <Form.Group>
                                <Field
                                    type='number'
                                    fluid
                                    name='grantLetterAmount'
                                    label='Prime confirmée par l’ANAH (€)'
                                    defaultValue={grantLetterAmount}
                                    onChange={onChange}
                                    error={formErrors.grantLetterAmount}
                                    lightBackground={true}
                                    disabled={grantLetterAmount > 0}
                                />
                            </Form.Group>
                            <Form.Group>
                                <DatePicker name='grantLetterDate' defaultValue={grantLetterDate} label="Date de la lettre d'octroi" onChange={onChange} lightBackground={true} />
                            </Form.Group>
                            <Form.Group className='d-flex flex-column'>
                                <Form.Label>Référence du dossier MPR</Form.Label>
                                <Space.Compact>
                                    <InputNumber
                                        prefix='MPR-202'
                                        addonAfter='-'
                                        maxLength={1}
                                        onChange={(value) => handleDossierReferenceChange('year', { value })}
                                        defaultValue={grantLetterDossierReference[7]}
                                    />
                                    <Input onChange={({ target }) => handleDossierReferenceChange('reference', target)} defaultValue={grantLetterDossierReference?.split('-')[2] || ''} />
                                </Space.Compact>
                            </Form.Group>
                        </>
                    )}

                    {(formState.dossierCEE || formState.file) && (
                        <Form.Group>
                            <Autocomplete
                                fluid
                                async={true}
                                loading={isLoading}
                                disabled={disabledFieldsSelection}
                                defaultValue={getDossierCEEDefaultValue()}
                                label={LABEL_AUTOCOMPLETE_DOSSIER}
                                onChange={onSearch}
                                onSelect={handleSelectDossierCEE}
                                options={dossiersCEE}
                                error={formErrors.dossierCEEId}
                                lightBackground={true}
                            />
                        </Form.Group>
                    )}

                    {uploadDocumentHelper.isAttestationType(formState) && formState?.quotation && selectedDossierCee && (
                        <Form.Group>
                            <Field
                                type='select'
                                fluid
                                name='operationUUID'
                                disabled={disabledFieldsSelection}
                                defaultValue={formState?.attestationOperationName}
                                label={LABEL_SELECT_OPERATION_RELATED_TO_ATTESTATION}
                                options={uploadDocumentHelper.getListOfOperationNeedingAnAttestation(formState.quotation, selectedDossierCee, formState?.documentType)}
                                onChange={onChange}
                                lightBackground={true}
                            />
                        </Form.Group>
                    )}

                    {getExtraJSXByDocumentType()}
                    {displayDpeOrAuditEnergeticForm(documentType, formErrors, onChange)}
                    {displaySignatureDate(documentType, onChange, getMinSignatureDate(), getMaxSignatureDate(), formState?.signatureDate)}
                    <Form.Group>{informationOperationSheetValidity && <span>Information : {informationOperationSheetValidity}</span>}</Form.Group>

                    {allSitePhotos.includes(documentType) && (
                        <Form.Group>
                            <Field fluid type='textarea' name='comment' label={LABEL_INPUT_COMMENT_OPTIONAL} error={formErrors.comment} onChange={onChange} lightBackground={true} />
                        </Form.Group>
                    )}

                    {uploadDocumentHelper.canShowHeatingPumpCoverageRateInput(documentType, selectedDossierCee) && (
                        <Form.Group>
                            <Field
                                fluid
                                textAppearance='initial'
                                type='number'
                                disabled={disabledFieldsSelection}
                                name='heatingPumpCoverageRate'
                                label={uploadDocumentHelper.formatHeatingPumpCoverageRateInputLabel(selectedDossierCee)}
                                min={uploadDocumentHelper.extractHeatingPumpCoverageRate(selectedDossierCee)}
                                error={formErrors.heatingPumpCoverageRate}
                                onChange={onChange}
                                lightBackground={true}
                            />
                        </Form.Group>
                    )}
                    {selectedDossierCee && <TaxNoticeForm documentType={documentType} onChange={onChange} formErrors={formErrors} formState={formState} dossierCEE={selectedDossierCee} />}
                    {displayPostRenovationReportForm(
                        documentType,
                        formErrors,
                        onChange,
                        uploadDocumentMinDates.postRenovationReportAccreditationDate(selectedDossierCee?.quotation?.signatureDate),
                        formState?.accreditationExpirationDate
                    )}
                    {displayCertificateOfCompliancyForm({
                        documentType,
                        formErrors,
                        onChange,
                        professionalSignatureDate: formState?.professionalSignatureDate,
                        beneficiarySignatureDate: formState?.beneficiarySignatureDate,
                    })}
                    {allSitePhotos.includes(documentType) && file && <UploadMultipleFiles file={file} onChange={onChange} updateFormState={updateFormState} formState={formState} />}
                    {canShowObsoleteOperationWarning() && (
                        <span style={{ color: '#e5733b' }}>
                            La date limite pour votre devis est fixée au 31 décembre 2023 en conformité avec les nouvelles réglementations. Les opérations relatives à la Pompe à chaleur air/eau ou
                            eau/eau (BAR-TH-104) et à la Rénovation globale (BAR-TH-164) ont été révoquées. Si vous envisagez de finaliser le devis à partir du 1er janvier 2024, vous devrez le réviser
                            en intégrant les nouvelles opérations désignées par BAR-TH-171, BAR-TH-172 pour les PAC et BAR-TH-174, BAR-TH-175 pour la rénovation d’ampleur. De plus la Chaudière
                            individuelle à haute performance énergétique (BAR-TH-106) n’est plus disponible.
                        </span>
                    )}
                    <Button fluid primary onClick={onSave} className={'mt-3'}>
                        Enregistrer
                    </Button>
                </Form>
            </ViewerFormContainer>
            <Loading show={loading} />
        </div>
    );
}
CreateUploadDocumentForm.propTypes = {
    formState: PropTypes.shape({
        documentType: PropTypes.string,
    }).isRequired,
    formErrors: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onResetDocumentCheckMessage: PropTypes.func,
};

const fileTypeViewers = {
    [uploadDocumentFileTypes.IMAGE]: ImageViewer,
    [uploadDocumentFileTypes.PDF]: PDFViewer,
};

function displayFileViewer(fileType, file) {
    const Viewer = fileTypeViewers[fileType];

    return Viewer ? <Viewer file={file} minHeight='60vh' /> : <></>;
}

function displayCertificateOfCompliancyForm({ documentType, formErrors, onChange, minDate, professionalSignatureDate, beneficiarySignatureDate }) {
    return (
        uploadDocumentHelper.isCertificateOfCompliancy(documentType) && (
            <>
                <Form.Group>
                    <DatePicker
                        name='professionalSignatureDate'
                        defaultValue={professionalSignatureDate}
                        label='Date de signature du professionnel'
                        onChange={onChange}
                        error={formErrors.professionalSignatureDate}
                    />
                </Form.Group>
                <Form.Group>
                    <DatePicker
                        name='beneficiarySignatureDate'
                        defaultValue={beneficiarySignatureDate}
                        label='Date de signature du bénéficiaire'
                        onChange={onChange}
                        error={formErrors.beneficiarySignatureDate}
                    />
                </Form.Group>
            </>
        )
    );
}

function displayPostRenovationReportForm(documentType, formErrors, onChange, minDate, accreditationExpirationDate) {
    return (
        uploadDocumentHelper.isPostRenovationReport(documentType) && (
            <>
                <Form.Group>
                    <Field type='text' fluid name='companyName' label="Raison sociale de l'organisme de contrôle" error={formErrors.companyName} onChange={onChange} lightBackground={true} />
                </Form.Group>
                <Form.Group>
                    <Field type='text' fluid name='siren' label="Numéro SIREN de l'organisme de contrôle" error={formErrors.siren} onChange={onChange} lightBackground={true} />
                </Form.Group>
                <Form.Group>
                    <Field
                        type='text'
                        fluid
                        name='accreditationNumber'
                        label="Numéro d’accréditation (COFRAC) ou équivalent de l'organisme de contrôle"
                        error={formErrors.accreditationNumber}
                        onChange={onChange}
                        lightBackground={true}
                    />
                </Form.Group>
                <Form.Group>
                    <DatePicker
                        name='accreditationExpirationDate'
                        defaultValue={accreditationExpirationDate}
                        label="Date de fin de validité de l’accréditation de l'organisme de contrôle"
                        onChange={onChange}
                        minDate={minDate}
                        lightBackground={true}
                    />
                </Form.Group>
                <Form.Group>
                    <Field type='text' fluid name='reference' label="Référence du rapport établi par l'organisme de contrôle" error={formErrors.reference} onChange={onChange} lightBackground={true} />
                </Form.Group>
            </>
        )
    );
}

function displaySignatureDate(documentType, onChange, minDate, maxDate, signatureDate) {
    return (
        uploadDocumentHelper.isSignatureDateRequired(documentType) && (
            <Form.Group>
                <DatePicker name='signatureDate' label='Date de signature' onChange={onChange} defaultValue={signatureDate} minDate={minDate} maxDate={maxDate} />
            </Form.Group>
        )
    );
}

function displayAuditForm(formErrors, onChange) {
    return (
        <>
            <Form.Group>
                <Field
                    type='select'
                    fluid
                    name='energeticClass'
                    label={'Sélectionner la classe énergétique du bâtiment'}
                    options={[
                        { label: 'A', value: 'A' },
                        { label: 'B', value: 'B' },
                        { label: 'C', value: 'C' },
                        { label: 'D', value: 'D' },
                        { label: 'E', value: 'E' },
                        { label: 'F', value: 'F' },
                        { label: 'G', value: 'G' },
                    ]}
                    error={formErrors.energeticClass}
                    onChange={onChange}
                    lightBackground={true}
                />
            </Form.Group>
            <Form.Group>
                <DatePicker name='signatureDate' label='Date de signature' error={formErrors.signatureDate} onChange={onChange} />
            </Form.Group>
        </>
    );
}

export default CreateUploadDocumentForm;
